import { Components, Theme } from '@mui/material';

const MuiFilledInput: Components<Theme>['MuiFilledInput'] = {
  defaultProps: {
    hiddenLabel: true,
    disableUnderline: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.background.inset,
      borderRadius: theme.shape.radii.medium,
      transition: 'all 0.15s ease-out',
      ':hover': {
        backgroundColor: theme.palette.background.inset,
      },
      '&.Mui-disabled,&[readonly]:not(.MuiSelect-root)': {
        backgroundColor: theme.palette.background.inset,
      },
      '&.Mui-focused': {
        boxShadow: `0px 0px 0px 1px ${theme.palette.common.white}, 0px 0px 0px 3px ${theme.palette.primary[300]}`,
      },
      '&.Mui-error': {
        border: `1px solid ${theme.palette.error.main}`,
        boxShadow: `0px 0px 0px 1px ${theme.palette.common.white}, 0px 0px 0px 3px ${theme.palette.error.main}`,
      },
      '.MuiInputAdornment-root': {
        marginTop: '0 !important',
      },
    }),
    input: ({ theme }) => ({
      /** @Todo suggestion: Theme composition to inherit InputBase padding values for a single source for input padding
       * https://mui.com/material-ui/customization/theming/#api
       */
      padding: theme.spacing(2, 3),
    }),
  },
};

export default MuiFilledInput;
