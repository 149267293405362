import * as React from 'react';
import { Components, Theme } from '@mui/material';
import RadioCheckedIcon from '../icons/Radio/Checked.svg';
import RadioIcon from '../icons/Radio/Radio.svg';

const MuiRadio: Components<Theme>['MuiRadio'] = {
  defaultProps: {
    icon: <RadioIcon />,
    checkedIcon: <RadioCheckedIcon />,
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.grey[300],
      ':hover,:active,:focus': {
        backgroundColor: 'transparent',
      },
      '&:hover .iconBorder': {
        fill: theme.palette.grey[200],
      },
      ':active .iconBorder': {
        fill: theme.palette.primary[100],
        color: theme.palette.primary.light,
      },
      ':focus .iconBorder': {
        color: theme.palette.primary[500],
      },
      '&.Mui-checked': {
        fill: theme.palette.primary[500],
        '& .iconCenter': {
          fill: theme.palette.common.white,
        },
        ':hover .iconBorder': {
          fill: theme.palette.primary.dark,
        },
      },
      '&.Mui-disabled': {
        '& .iconBorder': {
          color: theme.palette.grey[200],
          fill: theme.palette.grey[200],
        },
        '& .iconCenter': {
          fill: theme.palette.grey[300],
        },
      },
    }),
  },
};

export default MuiRadio;
