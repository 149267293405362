import { isEmpty } from 'lodash';
import MODEL_TYPE, { MODELS_BY_TYPE } from 'common/constants/modelType';
import { GretelModelConfig, GretelTabular, Trigger } from 'src/api/pilot/index';

export type TriggerVariant = 'Manual' | 'Schedule';
export type TriggerSchedule = '@daily' | '@weekly' | '@monthly' | 'custom';
export type MaybeCron = TriggerSchedule | string;
export type ScheduleItemsType = {
  label: string;
  value: TriggerSchedule;
  cronString?: string;
};

export type InputAction = {
  name: string;
  type: string; // usually `${ConnectionType}_source`, but not always
  connection?: string;
  config: {
    bucket?: string;
    container?: string;
    glob_filter?: string;
    path?: string;
    recursive?: boolean; // NOTE: unsure about this type, could it just be a string?
  };
};

type QueryItem = {
  name?: string;
  query?: string;
};

type TablenameItem = {
  name?: string;
};

export type RelationalInputAction = {
  name: string;
  type: string; // usually `${ConnectionType}_source`, but not always
  connection?: string;
  config: {
    sync?: {
      mode: string;
    };
    queries?: QueryItem[];
    tables?: TablenameItem[];
    bq_dataset?: string;
  };
};

export type SourceAction = InputAction | RelationalInputAction;

/**
 * Checks if an action is an `SourceAction`.
 */
export const isSourceAction = (action: WorkflowAction): action is InputAction =>
  action.type.endsWith('_source');

export type OutputAction = Omit<InputAction, 'type'> & {
  name: string;
  type: string; // usually `${ConnectionType}_destination`, but not always
  input: string; // e.g., 'model-train-run'
  config: InputAction['config'] & {
    filename?: string;
    input?: string;
    sync?: {
      mode: string;
    };
    dataset?: string;
    volume?: string;
    bq_dataset?: string;
  };
};
/**
 * Checks if an action is an `OutputAction`.
 */
export const isOutputAction = (
  action: WorkflowAction
): action is OutputAction => action.type.endsWith('_destination');

/**
 * Describes the `models` list a `model_config`. This will always be length === 1
 * and contain an object with a `MODEL_TYPE` key. The `object` type is not currently
 * available in console, but can be found in monogretel/skynet/models/<model type>/config.py.
 */
type SingleModel = [RequireOnlyOne<Record<MODEL_TYPE, object>>];
export const isSingleModel = (value?: unknown): value is SingleModel => {
  if (Array.isArray(value) && value.length === 1) {
    const [first] = value;
    if (typeof first === 'object') {
      const keys = Object.keys(first);
      return keys.length === 1 && keys[0] in MODELS_BY_TYPE;
    }
  }
  return false;
};

/**
 * Describes a `model_config` that contains everything needed to run the model.
 * This reflects the YAML config we send up when creating a model.
 */
export type ModelConfig = {
  name?: string;
  models: SingleModel;
};

/**
 * Checks if a `model_config` is a valid `ModelConfig`.
 */
export const isModelConfig = (value?: unknown): value is ModelConfig =>
  !!value &&
  typeof value === 'object' &&
  'models' in value &&
  (isSingleModel(value.models) || isEmpty(value.models)) &&
  (!('name' in value) || typeof value.name === 'string');

/**
 * Describes a `model_config` using a template defined in gretel-blueprints.
 * Possible `from` values can be found in manifest.json.
 */
export type ModelTemplate = { from: string };

/**
 * Checks if a `model_config` is a valid `ModelTemplate`.
 */
export const isModelTemplate = (value?: unknown): value is ModelTemplate =>
  !!value &&
  typeof value === 'object' &&
  'from' in value &&
  typeof value.from === 'string';

/**
 * A type describing possible values for `model_config`. Since one of these
 * keys must be set, this ensures we get useful type hints when rendering components.
 */
type ModelConfigOrName =
  | {
      model: never;
      model_config: ModelTemplate | ModelConfig;
    }
  | {
      model: string;
      model_config: never;
    };
/**
 * Checks if an `action.config` contains a valid model template name or config.
 */
export const isModelConfigOrName = (
  value?: Record<string, unknown>
): value is ModelConfigOrName =>
  !!value &&
  ('model' in value ||
    ('model_config' in value &&
      (isModelConfig(value.model_config) ||
        isModelTemplate(value.model_config))));

export enum WorkflowModelConfigType {
  GRETEL_MODEL = 'gretel_model',
  GRETEL_TABULAR = 'gretel_tabular',
}

export type ModelTrainRunAction = {
  name: string; // e.g., 'model-train-run', but users can change;
  type: WorkflowModelConfigType.GRETEL_MODEL;
  input: string;
  config: GretelModelConfig;
};

export type TabularModelTrainRunAction = {
  name: string; // e.g., 'model-train-run', but users can change;
  type: WorkflowModelConfigType.GRETEL_TABULAR;
  input: string;
  config: GretelTabular['GretelTabularConfig'];
};

export type ModelAction = ModelTrainRunAction | TabularModelTrainRunAction;

export const isModelAction = (action: WorkflowAction): action is ModelAction =>
  action.type === WorkflowModelConfigType.GRETEL_MODEL ||
  action.type === WorkflowModelConfigType.GRETEL_TABULAR;

export type WorkflowAction =
  | InputAction
  | RelationalInputAction
  | ModelAction
  | OutputAction;

// see src/Console/Workflows/utils `generateConfig` for how this gets built.
export type WorkflowConfig = {
  actions: WorkflowAction[];
  name: string;
  trigger?: Trigger;
};
