import * as React from 'react';
import { matchPath, useLocation } from 'react-router';
import {
  Box,
  Chip,
  Divider,
  List,
  ListSubheader,
  styled,
  Toolbar,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useHidePlayground, useSidenavUpdate } from 'common/featureFlags';
import LoaderFull from 'common/Loader/Full';
import { USER_INVITES_POLLING_INTERVAL } from 'constants/pollingIntervals';
import {
  Activity,
  Blueprint,
  Connection,
  CreditCard,
  Dashboard,
  Key,
  Playground,
  Project,
  Settings,
  UsageReport,
  User,
  Workflow,
} from 'icons/figma';
import { useLoadInvitesQuery } from 'src/api';
import { useZeroProjectsView } from 'src/Console/Projects/util';
import {
  ACTIVITY_ROUTE,
  BLUEPRINTS_ROUTE,
  CONNECTIONS_ROUTE,
  DASHBOARD_ROUTE,
  PLAYGROUND_ROUTE,
  PROJECTS_LIST_ROUTE,
  SELECTED_PROJECT_ROUTES_WITH_PROJECT_LAYOUT,
  USER_API_KEY_ROUTE,
  USER_BILLING_ROUTE,
  USER_INVITES_ROUTE,
  USER_ROUTE,
  USER_USAGE_ROUTE,
  WORKFLOWS_ROUTE,
} from 'src/routes';
import useConsoleRoute from 'utils/useConsoleRoute';
import AppSidebarLayout from '../Layout/Sidebar';
import { useSidebarStatus } from '../SidebarStatusContext';
import {
  FeaturedCardLink,
  PrimaryLinkItem,
  SecondaryLinkItem,
  UpdatedPrimaryLinkItem,
} from './sidebarLinkItems';
import { ToggleButton } from './ToggleButton';

const StyledDivider = styled(Divider)(({ theme }) => ({
  color: theme.palette.primary[100],
  margin: 0,
}));

const AppSidebar = () => {
  const { isSidebarOpen } = useSidebarStatus();
  const inviteCount =
    useLoadInvitesQuery(undefined, {
      pollingInterval: USER_INVITES_POLLING_INTERVAL,
    }).data?.filter(({ expired }) => !expired)?.length || 0;
  const { compileLocation } = useConsoleRoute();
  const ids = [uuid(), uuid(), uuid()];

  const { noProjectsView, loading } = useZeroProjectsView();
  const { value: showUpdatedSidenav, loading: isLoadingSidenavFeatureFlag } =
    useSidenavUpdate();
  const { value: hidePlayground, loading: isLoadingFeatureFlags } =
    useHidePlayground();

  const showPlaygroundLink =
    !isLoadingFeatureFlags && !hidePlayground && !isLoadingSidenavFeatureFlag;

  const { pathname } = useLocation();

  /**
   * Check if the the destination of the link is currently in the path.
   * If pathname is a child route of the pathname then show the parent as selected.
   *
   * example: project model page '/:project_id/models' would have the '/projects' link
   * be set as selected.
   * @param destPathname - string of the Route.pathname
   * @param isExact - boolean to enable exact matching. Useful for /user/me routes
   *
   * @returns boolean - true if given pathname is determined selected, otherwise false.
   */
  const checkSelected = React.useCallback(
    (destPathname: string, isExact: boolean = false): boolean => {
      /** When checking for project, check also for project child paths */
      if (destPathname === PROJECTS_LIST_ROUTE.path) {
        // If on projects list return true.
        if (pathname === PROJECTS_LIST_ROUTE.path) {
          return true;
        }
        // Create string array of project pathnames, exclude redirects.
        const projectRoutes = SELECTED_PROJECT_ROUTES_WITH_PROJECT_LAYOUT.map(
          ROUTE => 'path' in ROUTE && ROUTE?.path
        ).filter(path => typeof path === 'string') as string[];
        // If matchPath fails, null is returned so using !! we can ensure truthy/falsy.
        return !!matchPath(pathname, projectRoutes);
      }
      return !!matchPath(pathname, {
        path: destPathname,
        exact: isExact,
      });
    },
    [pathname]
  );

  if (loading) {
    return <LoaderFull />;
  }

  return (
    <AppSidebarLayout>
      <Toolbar
        variant="dense"
        sx={{
          mx: -6,
          boxShadow: 'none',
        }}
      />
      <ToggleButton />
      <List component="nav" aria-labelledby={ids[0]} sx={{ py: 4 }}>
        {showUpdatedSidenav ? (
          <UpdatedPrimaryLinkItem
            name="Dashboard"
            id="Dashboard"
            to={compileLocation(DASHBOARD_ROUTE)}
            icon={<Dashboard width={16} height={16} />}
            disabled={!isSidebarOpen}
            selected={checkSelected(DASHBOARD_ROUTE.path)}
          />
        ) : (
          <PrimaryLinkItem
            name="Dashboard"
            id="Dashboard"
            to={compileLocation(DASHBOARD_ROUTE)}
            icon={<Dashboard />}
            disabled={!isSidebarOpen}
          />
        )}

        {!noProjectsView && (
          <>
            {showUpdatedSidenav ? (
              <>
                <UpdatedPrimaryLinkItem
                  name="Activity"
                  id="Activity"
                  to={compileLocation(ACTIVITY_ROUTE)}
                  icon={<Activity width={16} height={16} />}
                  disabled={!isSidebarOpen}
                  selected={checkSelected(ACTIVITY_ROUTE.path)}
                />
                <UpdatedPrimaryLinkItem
                  name="Projects"
                  data-testid="sidebar-project"
                  id="ProjectList"
                  to={compileLocation(PROJECTS_LIST_ROUTE)}
                  icon={<Project width={16} height={16} />}
                  disabled={!isSidebarOpen}
                  selected={checkSelected(PROJECTS_LIST_ROUTE.path)}
                />
                <UpdatedPrimaryLinkItem
                  name="Blueprints"
                  id="Blueprints"
                  to={compileLocation(BLUEPRINTS_ROUTE)}
                  icon={<Blueprint width={16} height={16} />}
                  disabled={!isSidebarOpen}
                  selected={checkSelected(BLUEPRINTS_ROUTE.path)}
                />
                <UpdatedPrimaryLinkItem
                  name="Workflows"
                  data-testid="sidebar-workflows"
                  id="WorkflowsList"
                  to={compileLocation(WORKFLOWS_ROUTE)}
                  icon={<Workflow width={16} height={16} />}
                  disabled={!isSidebarOpen}
                  selected={checkSelected(WORKFLOWS_ROUTE.path)}
                />
                <UpdatedPrimaryLinkItem
                  name="Connections"
                  id="ConnectionList"
                  data-testid="sidebar-connection"
                  to={compileLocation(CONNECTIONS_ROUTE)}
                  icon={<Connection width={16} height={16} />}
                  disabled={!isSidebarOpen}
                  selected={checkSelected(CONNECTIONS_ROUTE.path)}
                />
              </>
            ) : (
              <>
                <PrimaryLinkItem
                  name="Activity"
                  id="Activity"
                  to={compileLocation(ACTIVITY_ROUTE)}
                  icon={<Activity />}
                  disabled={!isSidebarOpen}
                />
                <PrimaryLinkItem
                  name="Projects"
                  data-testid="sidebar-project"
                  id="ProjectList"
                  to={compileLocation(PROJECTS_LIST_ROUTE)}
                  icon={<Project />}
                  disabled={!isSidebarOpen}
                />
                <PrimaryLinkItem
                  name="Blueprints"
                  id="Blueprints"
                  to={compileLocation(BLUEPRINTS_ROUTE)}
                  icon={<Blueprint />}
                  disabled={!isSidebarOpen}
                />

                <PrimaryLinkItem
                  name="Workflows"
                  data-testid="sidebar-workflows"
                  id="WorkflowsList"
                  to={compileLocation(WORKFLOWS_ROUTE)}
                  icon={<Workflow />}
                  disabled={!isSidebarOpen}
                />
                <PrimaryLinkItem
                  name="Connections"
                  id="ConnectionList"
                  data-testid="sidebar-connection"
                  to={compileLocation(CONNECTIONS_ROUTE)}
                  icon={<Connection />}
                  disabled={!isSidebarOpen}
                />
              </>
            )}
          </>
        )}
      </List>
      {showPlaygroundLink &&
        (showUpdatedSidenav ? (
          <>
            <Divider sx={{ mb: 4 }} />
            <UpdatedPrimaryLinkItem
              name="Navigator"
              id="Playground"
              data-testid="sidebar-playground"
              to={compileLocation(PLAYGROUND_ROUTE)}
              icon={<Playground width={16} height={16} />}
              disabled={!isSidebarOpen}
              selected={checkSelected(PLAYGROUND_ROUTE.path)}
            />
          </>
        ) : (
          <FeaturedCardLink
            name="Navigator"
            id="Playground"
            data-testid="sidebar-playground"
            to={compileLocation(PLAYGROUND_ROUTE)}
            icon={<Playground />}
            disabled={!isSidebarOpen}
          />
        ))}
      <StyledDivider
        sx={{
          mt: showPlaygroundLink ? 4 : 0,
        }}
      />
      <List
        component="nav"
        aria-labelledby={ids[1]}
        sx={{
          mb: 0,
          mt: 4,
          pb: 4,
          overflowY: 'auto',
        }}
        subheader={
          <ListSubheader
            id={ids[1]}
            sx={theme => ({
              ...theme.typography.body2Strong,
              color: showUpdatedSidenav ? 'text.primary' : 'primary.light',
              lineHeight: 'unset',
              marginLeft: theme.spacing(3),
              marginBottom: theme.spacing(2),
              padding: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            })}
          >
            {showUpdatedSidenav ? 'Account' : 'My Account'}
          </ListSubheader>
        }
      >
        {showUpdatedSidenav ? (
          <>
            <UpdatedPrimaryLinkItem
              disabled={!isSidebarOpen}
              to={compileLocation(USER_API_KEY_ROUTE)}
              name="API Key"
              id="ApiKey"
              icon={<Key width={16} height={16} />}
              selected={checkSelected(USER_API_KEY_ROUTE.path, true)}
            />
            <UpdatedPrimaryLinkItem
              disabled={!isSidebarOpen}
              to={compileLocation(USER_INVITES_ROUTE)}
              name={
                <Box display="flex" justifyContent="space-between">
                  <span>Invites</span>
                  {inviteCount ? (
                    <Chip
                      label={inviteCount}
                      color="primary"
                      sx={theme => ({
                        ...theme.typography.body2Strong,
                        px: 2,
                        mr: 2,
                      })}
                    />
                  ) : null}
                </Box>
              }
              id="invites"
              icon={<User width={16} height={16} />}
              selected={checkSelected(USER_INVITES_ROUTE.path, true)}
            />
            <UpdatedPrimaryLinkItem
              disabled={!isSidebarOpen}
              to={compileLocation(USER_USAGE_ROUTE)}
              name="Usage"
              id="Usage"
              icon={<UsageReport width={16} height={16} />}
              selected={checkSelected(USER_USAGE_ROUTE.path, true)}
            />
            <UpdatedPrimaryLinkItem
              disabled={!isSidebarOpen}
              to={compileLocation(USER_BILLING_ROUTE)}
              name="Billing"
              id="Billing"
              icon={<CreditCard width={16} height={16} />}
              selected={checkSelected(USER_BILLING_ROUTE.path, true)}
            />
            <UpdatedPrimaryLinkItem
              disabled={!isSidebarOpen}
              to={compileLocation(USER_ROUTE)}
              name="Settings"
              id="Settings"
              icon={<Settings width={16} height={16} />}
              selected={checkSelected(USER_ROUTE.path, true)}
            />
          </>
        ) : (
          <>
            <SecondaryLinkItem
              disabled={!isSidebarOpen}
              to={compileLocation(USER_ROUTE)}
              name="Profile"
              id="Profile"
            />
            <SecondaryLinkItem
              disabled={!isSidebarOpen}
              to={compileLocation(USER_API_KEY_ROUTE)}
              name="API Key"
              id="ApiKey"
            />
            <SecondaryLinkItem
              disabled={!isSidebarOpen}
              to={compileLocation(USER_INVITES_ROUTE)}
              name={
                <Box display="flex" justifyContent="space-between">
                  <span>Invites</span>
                  {inviteCount ? (
                    <Chip
                      label={inviteCount}
                      color="primary"
                      sx={theme => ({
                        ...theme.typography.body2Strong,
                        px: 2,
                        mr: 2,
                      })}
                    />
                  ) : null}
                </Box>
              }
              id="Invites"
            />
          </>
        )}
      </List>
      <StyledDivider />
    </AppSidebarLayout>
  );
};

export default AppSidebar;
