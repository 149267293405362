import { api } from './baseApi';

export const addTagTypes = ['Projects'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getProjects: build.query<GetProjectsApiResponse, GetProjectsApiArg>({
        query: queryArg => ({
          url: `/projects`,
          params: {
            query: queryArg.query,
            limit: queryArg.limit,
            skip: queryArg.skip,
            sort: queryArg.sort,
            expand: queryArg.expand,
          },
        }),
        providesTags: ['Projects'],
      }),
      createProject: build.mutation<
        CreateProjectApiResponse,
        CreateProjectApiArg
      >({
        query: queryArg => ({
          url: `/projects`,
          method: 'POST',
          params: {
            name: queryArg.name,
            display_name: queryArg.displayName,
            description: queryArg.description,
            long_description: queryArg.longDescription,
            color: queryArg.color,
            public: queryArg['public'],
            runner_mode: queryArg.runnerMode,
            cluster_guid: queryArg.clusterGuid,
            domain_guid: queryArg.domainGuid,
          },
        }),
        invalidatesTags: ['Projects'],
      }),
      getProject: build.query<GetProjectApiResponse, GetProjectApiArg>({
        query: queryArg => ({
          url: `/projects/${queryArg.projectGuid}`,
          params: { expand: queryArg.expand },
        }),
        providesTags: ['Projects'],
      }),
      updateProject: build.mutation<
        UpdateProjectApiResponse,
        UpdateProjectApiArg
      >({
        query: queryArg => ({
          url: `/projects/${queryArg.projectGuid}`,
          method: 'PUT',
          params: {
            name: queryArg.name,
            display_name: queryArg.displayName,
            description: queryArg.description,
            long_description: queryArg.longDescription,
            color: queryArg.color,
            public: queryArg['public'],
          },
        }),
        invalidatesTags: ['Projects'],
      }),
      deleteProject: build.mutation<
        DeleteProjectApiResponse,
        DeleteProjectApiArg
      >({
        query: queryArg => ({
          url: `/projects/${queryArg.projectGuid}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Projects'],
      }),
      createProjectInvite: build.mutation<
        CreateProjectInviteApiResponse,
        CreateProjectInviteApiArg
      >({
        query: queryArg => ({
          url: `/projects/${queryArg.projectGuid}/invites`,
          method: 'POST',
          params: { email: queryArg.email, level: queryArg.level },
        }),
        invalidatesTags: ['Projects'],
      }),
      getProjectMembers: build.query<
        GetProjectMembersApiResponse,
        GetProjectMembersApiArg
      >({
        query: queryArg => ({
          url: `/projects/${queryArg.projectGuid}/members`,
          params: { expand: queryArg.expand },
        }),
        providesTags: ['Projects'],
      }),
      deleteProjectMember: build.mutation<
        DeleteProjectMemberApiResponse,
        DeleteProjectMemberApiArg
      >({
        query: queryArg => ({
          url: `/projects/${queryArg.projectGuid}/members/${queryArg.userId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Projects'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as ProjectsAPI };
export type GetProjectsApiResponse = /** status 200 OK */ GetProjectsResponse;
export type GetProjectsApiArg = {
  query?: string;
  limit?: number;
  skip?: number;
  sort?: string[];
  expand?: ('members' | 'models' | 'artifacts')[];
};
export type CreateProjectApiResponse =
  /** status 200 OK */ CreateProjectResponse;
export type CreateProjectApiArg = {
  name?: string;
  displayName?: string;
  description?: string;
  longDescription?: string;
  color?: string;
  public?: boolean;
  runnerMode?: 'cloud' | 'hybrid';
  clusterGuid?: string;
  domainGuid?: string;
};
export type GetProjectApiResponse = /** status 200 OK */ GetProjectResponse;
export type GetProjectApiArg = {
  projectGuid: string;
  expand?: ('members' | 'models' | 'artifacts')[];
};
export type UpdateProjectApiResponse =
  /** status 200 OK */ UpdateProjectResponse;
export type UpdateProjectApiArg = {
  projectGuid: string;
  name?: string;
  displayName?: string;
  description?: string;
  longDescription?: string;
  color?: string;
  public?: boolean;
};
export type DeleteProjectApiResponse = /** status 200 OK */ string;
export type DeleteProjectApiArg = {
  projectGuid: string;
};
export type CreateProjectInviteApiResponse =
  /** status 200 OK */ CreateProjectInviteResponse;
export type CreateProjectInviteApiArg = {
  projectGuid: string;
  email?: string;
  level?: number;
};
export type GetProjectMembersApiResponse =
  /** status 200 OK */ GetProjectResponse;
export type GetProjectMembersApiArg = {
  projectGuid: string;
  expand?: ('members' | 'models' | 'artifacts')[];
};
export type DeleteProjectMemberApiResponse = /** status 200 OK */ string;
export type DeleteProjectMemberApiArg = {
  projectGuid: string;
  userId: string;
};
export type Project = {
  _id: string;
  guid: string;
  name?: string;
  display_name?: string;
  description?: string;
  long_description?: string;
  owner: string;
  color?: string;
  public?: boolean;
  runner_mode: Runner_mode;
  cluster_guid?: string;
  domain_guid?: string;
  modified: string;
  created: string;
};
export type GetProjectsResponseData = {
  projects: Project[];
};
export type GetProjectsResponseMeta = {
  project_count: number;
};
export type GetProjectsResponse = {
  data: GetProjectsResponseData;
  meta: GetProjectsResponseMeta;
};
export type GoogleProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type Status = {
  code?: number;
  message?: string;
  details?: GoogleProtobufAny[];
};
export type CreateProjectResponseData = {
  id: string;
  guid: string;
};
export type CreateProjectResponse = {
  data: CreateProjectResponseData;
};
export type GetProjectResponseData = {
  project: Project;
};
export type GetProjectResponse = {
  data: GetProjectResponseData;
};
export type UpdateProjectResponseData = {
  project: Project;
};
export type UpdateProjectResponse = {
  data: UpdateProjectResponseData;
};
export type ProjectMembership = {
  user_id: string;
  user_email: string;
  project_id: string;
  project_guid: string;
  project_name?: string;
  display_name?: string;
  invite?: boolean;
  invited_by?: string;
  invited_at?: string;
  last_modified?: string;
  level: number;
  accepted_at?: string;
  expires?: string;
};
export type CreateProjectInviteResponseData = {
  invite: ProjectMembership;
};
export type CreateProjectInviteResponse = {
  data: CreateProjectInviteResponseData;
};
export enum Runner_mode {
  Cloud = 'cloud',
  Hybrid = 'hybrid',
}
export const {
  useGetProjectsQuery,
  useLazyGetProjectsQuery,
  useCreateProjectMutation,
  useGetProjectQuery,
  useLazyGetProjectQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useCreateProjectInviteMutation,
  useGetProjectMembersQuery,
  useLazyGetProjectMembersQuery,
  useDeleteProjectMemberMutation,
} = injectedRtkApi;
