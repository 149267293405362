import { Components, Theme } from '@mui/material';

const MuiCircularProgress: Components<Theme>['MuiCircularProgress'] = {
  styleOverrides: {
    colorPrimary: ({ theme }) => ({
      color: theme.palette.primary.main,
    }),
    colorSecondary: ({ theme }) => ({
      color: theme.palette.primary[50],
    }),
  },
};

export default MuiCircularProgress;
