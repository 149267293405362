import * as React from 'react';
import { Components, Theme } from '@mui/material';
import CheckboxSelectedIcon from '../icons/Checkbox/Checked.svg';
import CheckboxIndeterminateIcon from '../icons/Checkbox/Indeterminate.svg';
import CheckboxIcon from '../icons/Checkbox/Unchecked.svg';

const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {
    icon: <CheckboxIcon />,
    checkedIcon: <CheckboxSelectedIcon />,
    indeterminateIcon: <CheckboxIndeterminateIcon />,
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      padding: 0,
      paddingRight: theme.spacing(2),
      '& svg': {
        height: theme.spacing(4),
        width: theme.spacing(4),
      },
      '& .iconBorder': { fill: theme.palette.grey[500] },
      '& .iconCenter': { fill: theme.palette.common.white },
      '&:hover,&.Mui-hover': {
        '.iconBorder': { fill: theme.palette.grey[700] },
      },
      '&:active,&.Mui-active': {
        '.iconBorder': { fill: theme.palette.primary.light },
        '.iconCenter': { fill: theme.palette.primary[200] },
      },
      '&:disabled,&.Mui-disabled': {
        '.iconBorder': { fill: theme.palette.grey[300] },
        '.iconCenter': { fill: theme.palette.grey[200] },
      },
      '&:focus,&.Mui-focusVisible': {
        borderRadius: theme.shape.radii.small,
        boxShadow: `
          0px 0px 0px 1px ${theme.palette.common.white},
          0px 0px 0px 3px ${theme.palette.primary[300]}`,
      },
      '&:invalid,&.Mui-error': {
        '.iconBorder': { fill: theme.palette.error.main },
      },
      '&:checked,&.Mui-checked': {
        '& .iconBorder': { fill: theme.palette.primary.main },
        '&:hover,&.Mui-hover': {
          '.iconBorder': { fill: theme.palette.primary.dark },
          '.iconCenter': { fill: theme.palette.common.white },
        },
        '&:active,&.Mui-active': {
          '.iconBorder': { fill: theme.palette.primary.light },
          '.iconCenter': { fill: theme.palette.common.white },
        },
        '&:disabled,&.Mui-disabled': {
          '.iconBorder': { fill: theme.palette.grey[300] },
          '.iconCenter': { fill: theme.palette.common.white },
        },
      },
    }),
  },
};

export default MuiCheckbox;
