import { Components, Theme } from '@mui/material';

const MuiPaper: Components<Theme>['MuiPaper'] = {
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    rounded: {
      borderRadius: 4,
    },
  },
};

export default MuiPaper;
