import { PaletteOptions } from '@mui/material';
import { common, grey } from './colorValues';
import { paletteDark } from './paletteDark';
import { paletteLight } from './paletteLight';

declare module '@mui/material/index' {
  interface Color {
    950: string;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    /** @deprecated Use border.default or border.strong instead*/
    divider: string;
    border: {
      default: string;
      strong: string;
    };
  }

  interface TypeBackground {
    /** @deprecated Use background.secondary instead*/
    paper: string;
    secondary: string;
    inset: string;
  }
  interface PaletteOptions {
    /** @deprecated Use border.default or border.strong instead*/
    divider?: string;
    border?: {
      default?: string;
      strong?: string;
    };
  }
}

const sharedPalette: PaletteOptions = {
  common,
  grey: grey,
};

export const light: PaletteOptions = {
  mode: 'light',
  ...sharedPalette,
  ...paletteLight,
};

export const dark: PaletteOptions = {
  mode: 'dark',
  ...sharedPalette,
  ...paletteDark,
};
