import React from 'react';

/** hides the ZenDesk widget, removing the chat window Floating Action Button to the corner of the app */
export const hideZenDeskWidget = () => {
  window.zE?.('webWidget', 'hide');
};

/** shows the ZenDesk widget, adding the chat window Floating Action Button to the corner of the app */
export const showZenDeskWidget = () => {
  window.zE?.('webWidget', 'show');
};

/** opens the ZenDesk widget */
export const openZenDeskWidget = () => {
  window.zE?.('webWidget', 'open');
};

/** closes the ZenDesk widget */
export const closeZenDeskWidget = () => {
  window.zE?.('webWidget', 'close');
};

export const useHideZenDeskWidget = () => {
  React.useEffect(() => {
    hideZenDeskWidget();
    return () => showZenDeskWidget();
  }, []);
};
