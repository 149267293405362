import { getFileStats } from 'common/utils/data';
import { fetchRemote, serializableError } from 'utils/fetch';
import { ExamplePrompt, RawExamplePrompt } from '../types/Blueprint';
/**
 *
 * A collection of utility methods intended to be used in GretelAPI.
 */

/**
 * This utility is used to download remote files and immediately
 * process them for information about the file, including its records. Used in cases
 * where we don't need to keep the file itself (e.g., data previews)
 */
export const fetchAndProcessFile = async (
  url: string,
  method: string,
  fileName: string
) => {
  const response = await fetchRemote(url, { method });
  if (response.status !== 200) {
    throw serializableError('File not available');
  }
  const file = new File([await response.blob()], fileName);

  const { result, columnCount, rowCount, fileType } = await getFileStats({
    file,
  });
  return { result, columnCount, rowCount, fileType };
};

/**
 * prompt values coming from gretel-blueprints repo may be
 * string or string[]. string[] represents multiline prompts.
 * Before passing on to the rest of our app, join multline prompts into a
 * single string with newlines.
 */
export const formatPlaygroundExamplePrompts = (
  example: RawExamplePrompt
): ExamplePrompt => {
  if (Array.isArray(example.value)) {
    return { ...example, value: example.value.join('\n') };
  } else {
    return example as ExamplePrompt;
  }
};
