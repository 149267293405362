/**
 * @description
 * This component is heavily inspired by a component we used to import but had
 * limitations (wasn't using MUI, couldn't override component).
 * @see https://github.com/JedWatson/react-input-autosize
 * The source code was small enough I decided to port the concept.
 */
import React from 'react';
import { styled, TextField, TextFieldProps } from '@mui/material';

type Props = Omit<TextFieldProps, 'ref' | 'value'> & {
  maxLength: number;
  value: string;
};

/** here we are copying all our font relevant styles from a declaration onto our node */
const copyStyles = (styles: CSSStyleDeclaration, node: HTMLElement) => {
  node.style.fontSize = styles.fontSize;
  node.style.fontFamily = styles.fontFamily;
  node.style.fontWeight = styles.fontWeight;
  node.style.fontStyle = styles.fontStyle;
  node.style.letterSpacing = styles.letterSpacing;
  node.style.textTransform = styles.textTransform;
  node.style.padding = styles.padding;
  node.style.border = styles.border;
};

/**
 * We use this component for our Project Header input, so these styles reflect those.
 * If we need to use this component in multiple places please move these styles to
 * ProjectPageHeader to be passed in.
 */
const Input = styled(TextField)(({ theme }) => ({
  '& input': {
    outline: 'none',
    padding: theme.spacing(1, 2),
    border: '1px solid transparent',
    borderRadius: theme.shape.radii.medium,
    ...theme.typography.h2,
    height: theme.typography.h1.fontSize,
    fontSize: '24px',
    '&:hover': {
      border: `1px solid ${theme.palette.grey[600]}`,
    },
    '&:focus': {
      backgroundColor: theme.palette.common.white,
      boxShadow: `0px 0px 0px 1px ${theme.palette.common.white}, 0px 0px 0px 3px ${theme.palette.primary[300]}`,
    },
  },
}));

export const AutosizeInput = ({ value, maxLength, ...props }: Props) => {
  const inputRef = React.useRef<HTMLInputElement>();
  const sizerRef = React.useRef<HTMLDivElement>();
  const [width, setWidth] = React.useState(0);

  React.useLayoutEffect(() => {
    if (sizerRef.current && inputRef.current) {
      copyStyles(window.getComputedStyle(inputRef.current), sizerRef.current);
      setWidth(sizerRef.current?.scrollWidth + 2);
    }
  }, [sizerRef.current?.scrollWidth, value]);

  const truncatedValue = value.slice(0, maxLength);

  return (
    <>
      {/**
       * the basic concept here is we create a hidden div with all the same
       * (text) styling as our input, and then let the render engine size that
       * out itself. after that (in useLayoutEffect) we read the width, and
       * apply that width to our input. pretty simple and straightforward.
       */}
      <div
        ref={sizerRef as React.MutableRefObject<HTMLDivElement>}
        style={{
          // hide it
          opacity: 0,
          // remove it from flow
          position: 'absolute',
          // avoid clicking on it
          pointerEvents: 'none',
          // don't remove trailing whitespace (so we know it's there when sizing)
          whiteSpace: 'pre',
        }}
        aria-hidden="true"
      >
        {truncatedValue || props.placeholder}
      </div>
      <Input
        {...props}
        value={truncatedValue}
        type="text"
        inputRef={inputRef}
        sx={Object.assign({ width, boxSizing: 'content-box' }, props.sx)}
      />
    </>
  );
};
