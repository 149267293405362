import * as React from 'react';
import { Box, SxProps, Typography, TypographyProps } from '@mui/material';

type VerticalLabelProps = React.PropsWithChildren<{
  label?: React.ReactNode;
  optional?: boolean;
  TypographyProps?: TypographyProps;
  sx?: SxProps;
}> &
  React.HTMLProps<HTMLLabelElement>;

const VerticalLabel = React.forwardRef<HTMLLabelElement, VerticalLabelProps>(
  (
    {
      label,
      optional = false,
      children,
      TypographyProps = { variant: 'body2Strong' },
      sx,
      ...labelProps
    },
    ref
  ) => {
    return (
      <Box sx={sx} component="label" {...labelProps} ref={ref}>
        <div>
          <Typography
            sx={{
              display: 'inline-flex',
              mb: 1,
              mr: 2,
            }}
            {...TypographyProps}
          >
            {label}
          </Typography>
          {optional && (
            <Typography variant="body2" color="textSecondary">
              (Optional)
            </Typography>
          )}
        </div>
        {children}
      </Box>
    );
  }
);

VerticalLabel.displayName = 'VerticalLabel';

export default VerticalLabel;
