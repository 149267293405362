import { Components, Theme } from '@mui/material';

const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body2,
      marginLeft: 0,
      marginRight: 0,
      marginTop: theme.spacing(2),
      color: theme.palette.grey[600],
      '& svg': {
        height: theme.spacing(4),
        width: theme.spacing(4),
        marginRight: theme.spacing(1),
        verticalAlign: 'text-top',
      },
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
    }),
  },
};

export default MuiFormHelperText;
