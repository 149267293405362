import { MODEL_TYPE } from 'constants/modelType';
/**
 * A file to store external (i.e., non-Console) urls that we link to somewhere
 * on Console.
 */

/* General */
export const CONTACT_US = 'https://gretel.ai/contact';
export const DISCORD_GROUP = 'https://gretel.ai/discord';
export const PRICING = 'https://gretel.ai/pricing';
export const STATUS_PAGE = 'https://status.gretel.ai/ ';

/* Legal */
export const GRETEL_CORP_HOME = 'https://gretel.ai/';
export const GRETEL_PRIVACY_STATEMENT = 'https://gretel.ai/privacy-statement';
export const GRETEL_TERMS_OF_SERVICE = 'https://gretel.ai/terms';
export const GRETEL_DPA = 'https://gretel.ai/data-processing-addendum';
export const GRETEL_COOKIE_POLICY =
  'https://gretel.ai/privacy-statement#cookies-and-tracking';

/**
 * Misc Documentation Links
 */
export const DOCUMENTATION_HUB = 'https://docs.gretel.ai/';
export const PRIVACY_PROTECTION =
  'https://docs.gretel.ai/reference/synthetics/privacy-protection';
export const GRETEL_FAQ_IMPROVE_ACCURACY =
  'https://docs.gretel.ai/reference/synthetics/tips-improve-synthetic-data-accuracy';
export const GRETEL_FAQ_SUPPORTED_FORMATS =
  'https://docs.gretel.ai/gretel-basics/fundamentals/inputs-and-outputs';
export const MODEL_CONFIG_DOCS = {
  [MODEL_TYPE.SYNTHETICS]:
    'https://docs.gretel.ai/create-synthetic-data/models/model-configurations',
  [MODEL_TYPE.CLASSIFY]:
    'https://docs.gretel.ai/create-synthetic-data/models/classify',
  [MODEL_TYPE.TRANSFORM]:
    'https://docs.gretel.ai/create-synthetic-data/models/transform',
};

/* Links to configuration variables */
const MODEL_CONFIG_REPO =
  'https://github.com/gretelai/gretel-blueprints/blob/main/config_templates/gretel/';
export const TABULAR_ACTGAN_CONFIG = `${MODEL_CONFIG_REPO}synthetics/tabular-actgan.yml`;

/*
 * Connections Documentation links
 */
export const OBJECT_STORAGE_DOCS =
  'https://docs.gretel.ai/reference/connectors/object-storage';
export const AWS_PERMISSIONS_DOCS =
  'https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors/object-storage/amazon-s3';
export const MYSQL_PERMISSIONS_DOCS =
  'https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors/database/mysql';
export const POSTGRES_PERMISSIONS_DOCS =
  'https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors/database/postgresql';
export const ORACLE_PERMISSIONS_DOCS =
  'https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors/database/oracle-database';
export const MS_SQL_PERMISSIONS_DOCS =
  'https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors/database/ms-sql-server';
export const SNOWFLAKE_PERMISSIONS_DOCS =
  'https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors/data-warehouse/snowflake';
export const BIG_QUERY_PERMISSIONS_DOCS =
  'https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors/data-warehouse/bigquery';
export const AZURE_PERMISSIONS_DOCS =
  'https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors/object-storage/azure-blob';
export const DATABRICKS_PERMISSIONS_DOCS =
  'https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors/data-warehouse/databricks#permissions';
export const GLOBFILTER_DOCS =
  'https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors/object-storage#glob-filter-and-path-configurations';
export const GCS_SERVICE_ACCOUNT_DOCS =
  'https://docs.gretel.ai/create-synthetic-data/workflows-and-connectors/connectors/object-storage/google-cloud-storage#create-a-service-account';

/* Hybrid */
export const GRETEL_HYBRID_DOCUMENTATION =
  'https://docs.gretel.ai/guides/environment-setup/running-gretel-hybrid';

/* Playground Forms */
export const PLAYGROUND_FEEDBACK =
  'https://gretelai.typeform.com/modelplayground';
export const PLAYGROUND_FAQ =
  'https://docs.gretel.ai/create-synthetic-data/models/navigator/faq';
/* Navigator examples */
export const NAVIGATOR_EXAMPLE =
  'https://docs.gretel.ai/create-synthetic-data/models/navigator/examples';

export const TABLLM_GETTING_STARTED =
  'https://docs.gretel.ai/create-synthetic-data/models/tabular-llm/getting-started';
export const TABLLM_TIPS =
  'https://docs.gretel.ai/create-synthetic-data/models/tabular-llm/prompts-tips-and-best-practices';
