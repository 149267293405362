import * as React from 'react';
import { Components, Theme } from '@mui/material';
import { ChevronRight } from 'icons/figma';

const MuiBreadcrumbs: Components<Theme>['MuiBreadcrumbs'] = {
  defaultProps: {
    separator: <ChevronRight />,
  },
  styleOverrides: {
    separator: ({ theme }) => ({
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: theme.spacing(4),
      height: theme.spacing(4),
      alignItems: 'center',
    }),
  },
};

export default MuiBreadcrumbs;
