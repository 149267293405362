import * as React from 'react';
import ProjectPageHeader from './ProjectPageHeader';

const ProjectLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <ProjectPageHeader />
      {children}
    </>
  );
};

ProjectLayout.displayName = 'ProjectLayout';

export default ProjectLayout;
