import * as React from 'react';
import {
  CircularProgress,
  FormHelperTextProps,
  Grid,
  SvgIcon,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { AlertTriangle } from 'icons/figma';

const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export type UserEmailInputProps = Partial<TextFieldProps> & {
  onChange: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => boolean;
  isLoading?: boolean;
  InputProps?: object;
  helperText?: React.ReactNode;
};

const UserEmailInput = React.forwardRef<HTMLInputElement, UserEmailInputProps>(
  (
    {
      onChange,
      onBlur = () => true,
      isLoading = false,
      helperText = '',
      FormHelperTextProps = {},
      InputProps = {},
      ...textFieldProps
    },
    ref
  ) => {
    const [error, setError] = React.useState<string>();

    const handleBlur = React.useCallback(
      event => {
        setError(
          EMAIL_REGEX.test(event?.target?.value) || !event?.target?.value
            ? undefined
            : `'${event?.target?.value}' is not a valid email address`
        );
        return onBlur(event);
      },
      [setError, onBlur]
    );

    const handleChange = React.useCallback(
      event =>
        onChange(
          EMAIL_REGEX.test(event?.target?.value)
            ? event
            : { ...event, target: { ...event.target, value: undefined } }
        ),
      [onChange]
    );

    return (
      <TextField
        placeholder="Invite Users"
        fullWidth
        {...textFieldProps}
        sx={theme => ({
          minWidth: '400px',
          ...(error
            ? {
                '& fieldset': {
                  borderColor: theme.palette.error[500],
                },
              }
            : undefined),
        })}
        ref={ref}
        FormHelperTextProps={
          {
            ...FormHelperTextProps,
            component: 'div',
          } as Partial<FormHelperTextProps>
        }
        helperText={
          error ? (
            <Grid container alignItems="center">
              <SvgIcon
                sx={theme => ({
                  color: theme.palette.error.main,
                  mr: 2,
                  height: '16px',
                  width: '16px',
                })}
              >
                <AlertTriangle />
              </SvgIcon>{' '}
              <Typography color="error">{error}</Typography>
            </Grid>
          ) : (
            helperText
          )
        }
        onChange={handleChange}
        onBlur={handleBlur}
        InputProps={{
          ...InputProps,
          endAdornment: isLoading ? (
            <CircularProgress sx={{ mr: 2 }} size={20} />
          ) : null,
        }}
      />
    );
  }
);

UserEmailInput.displayName = 'UserEmailInput';

export default UserEmailInput;
