import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SvgIcon,
} from '@mui/material';
import { DOCUMENTATION_HUB } from 'common/constants/urls';
import { Tip } from 'icons/figma';

type CancelModelModalProps = {
  onCancelModel: () => void;
  onClose?: () => void;
  open: boolean;
};

const CancelModelModal = ({
  onCancelModel,
  onClose = () => undefined,
  open,
}: CancelModelModalProps) => {
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onClose}
      open={open}
    >
      <DialogTitle>Are you sure you want to cancel?</DialogTitle>

      <DialogContent>
        <DialogContentText>
          If you quit now all progress will be lost.
        </DialogContentText>
        <Box
          sx={theme => ({
            border: 1,
            borderColor: 'border.default',
            borderRadius: theme.shape.radii.base,
            marginTop: 1.5,
            padding: 4,
            display: 'flex',
          })}
        >
          <SvgIcon
            sx={{
              height: 24,
              width: 24,
              mr: 2,
            }}
          >
            <Tip />
          </SvgIcon>
          <div>
            <DialogContentText>
              Not getting the results you were hoping for? Check out our tips
              for improving synthetic data quality.
            </DialogContentText>
            <Button
              color="secondary"
              size="small"
              href={DOCUMENTATION_HUB}
              target="_blank"
            >
              Read the docs
            </Button>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={onCancelModel}
          data-testid="action-confirm"
        >
          Stop training
        </Button>
        <Button onClick={onClose} variant="text">
          Nevermind
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelModelModal;
