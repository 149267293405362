import React from 'react';
import { USER_ACCESS_LEVEL } from 'common/constants/userAccessLevel';
import {
  useHybridFeature,
  useProjectCreationRestricted,
} from 'common/featureFlags';
import LocalStorageHelper from 'common/localStorageHelper';
import { useProjectsQuery } from 'src/api';
import { useListClustersQuery } from 'src/api/pilot/clusters';
import { Project } from 'src/api/types/Project';

export const setDefaultProject = (projectGuid: string) => {
  LocalStorageHelper.setItem('lastUsedProject', projectGuid);
};

export const getDefaultProject = () => {
  let projectGuid: string | null = null;

  try {
    projectGuid = localStorage.getItem('lastUsedProject');
  } catch (err) {
    // swallow err
  }
  return projectGuid;
};

export const writeableProjectsForUser = (projects: Project[]) => {
  return projects.filter(
    project => project.level && project.level >= USER_ACCESS_LEVEL.READ_WRITE
  );
};

export const useDefaultOrLastUsedProject = () => {
  // If we have projects, show last used project, if no last used project then
  // use most recently updated project
  const projectsQuery = useProjectsQuery(undefined);

  const writeableProjects = React.useMemo(
    () =>
      writeableProjectsForUser(
        projectsQuery.data ? Object.values(projectsQuery.data) : []
      ),
    [projectsQuery.data]
  );

  let projectGuid = '';

  if (writeableProjects.length > 0) {
    const lastUsedGuid = getDefaultProject();
    const lastUsedProject = writeableProjects.find(
      project => project.guid === lastUsedGuid
    );
    if (lastUsedProject) {
      projectGuid = lastUsedProject.guid;
    } else {
      projectGuid = writeableProjects[writeableProjects.length - 1].guid;
    }
  }

  return projectGuid;
};

export const useHybridEnabled = () => {
  const { value: hybridFeatureEnabled } = useHybridFeature();
  const { data } = useListClustersQuery({});
  const clusters = React.useMemo(() => data?.clusters ?? [], [data]);
  const hasHybridClusters = clusters.length > 0;

  return hybridFeatureEnabled || hasHybridClusters;
};

export const useZeroProjectsView = () => {
  const projectsQuery = useProjectsQuery(undefined);
  const projects = projectsQuery.data;
  const hasNoProjects = projects && Object.keys(projects).length === 0;
  const {
    value: projectCreationRestricted,
    loading: projectCreationFlagLoading,
  } = useProjectCreationRestricted();

  const noProjectsView = hasNoProjects && projectCreationRestricted;
  const loading = projectCreationFlagLoading || projectsQuery.isLoading;
  return { noProjectsView, loading };
};
