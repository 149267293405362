export enum ActivityEventPredicate {
  PREDICATE_CREATED_AT = 'PREDICATE_CREATED_AT',
}
export enum ClusterCloudProviderType {
  UNKNOWN = 'UNKNOWN',
  AWS = 'AWS',
  GCP = 'GCP',
  AZURE = 'AZURE',
}
export enum ClusterStatusHealthStatus {
  HEALTH_STATUS_UNKNOWN = 'HEALTH_STATUS_UNKNOWN',
  HEALTH_STATUS_HEALTHY = 'HEALTH_STATUS_HEALTHY',
  HEALTH_STATUS_DEGRADED = 'HEALTH_STATUS_DEGRADED',
  HEALTH_STATUS_UNHEALTHY = 'HEALTH_STATUS_UNHEALTHY',
}
export enum ConfigAsymmetricKeyMetadataAlgorithm {
  UNKNOWN_ALGORITHM = 'UNKNOWN_ALGORITHM',
  RSA_4096_OAEP_SHA256 = 'RSA_4096_OAEP_SHA256',
}
export enum ConnectionValidationStatus {
  VALIDATION_STATUS_UNKNOWN = 'VALIDATION_STATUS_UNKNOWN',
  VALIDATION_STATUS_VALIDATING = 'VALIDATION_STATUS_VALIDATING',
  VALIDATION_STATUS_VALID = 'VALIDATION_STATUS_VALID',
  VALIDATION_STATUS_INVALID = 'VALIDATION_STATUS_INVALID',
}
export enum CreateNavigatorPromptRequestType {
  PROMPT_TYPE_GPT = 'PROMPT_TYPE_GPT',
  PROMPT_TYPE_TABULAR = 'PROMPT_TYPE_TABULAR',
}
export enum CreateWorkflowRequestRunnerMode {
  RUNNER_MODE_UNSET = 'RUNNER_MODE_UNSET',
  RUNNER_MODE_CLOUD = 'RUNNER_MODE_CLOUD',
  RUNNER_MODE_HYBRID = 'RUNNER_MODE_HYBRID',
  RUNNER_MODE_INVALID = 'RUNNER_MODE_INVALID',
}
export enum NavigatorPromptType {
  PROMPT_TYPE_GPT = 'PROMPT_TYPE_GPT',
  PROMPT_TYPE_TABULAR = 'PROMPT_TYPE_TABULAR',
}
export enum ProjectRunnerMode {
  RUNNER_MODE_UNSET = 'RUNNER_MODE_UNSET',
  RUNNER_MODE_CLOUD = 'RUNNER_MODE_CLOUD',
  RUNNER_MODE_HYBRID = 'RUNNER_MODE_HYBRID',
  RUNNER_MODE_INVALID = 'RUNNER_MODE_INVALID',
}
export enum ServerlessTenantCloudProviderInfoProvider {
  UNKNOWN = 'UNKNOWN',
  AWS = 'AWS',
  AZURE = 'AZURE',
  GCP = 'GCP',
}
export enum UpdateNavigatorPromptRequestType {
  PROMPT_TYPE_GPT = 'PROMPT_TYPE_GPT',
  PROMPT_TYPE_TABULAR = 'PROMPT_TYPE_TABULAR',
}
export enum ValidateConnectionCredentialsResponseStatus {
  VALIDATION_STATUS_UNKNOWN = 'VALIDATION_STATUS_UNKNOWN',
  VALIDATION_STATUS_VALIDATING = 'VALIDATION_STATUS_VALIDATING',
  VALIDATION_STATUS_VALID = 'VALIDATION_STATUS_VALID',
  VALIDATION_STATUS_INVALID = 'VALIDATION_STATUS_INVALID',
}
export enum ValidateWorkflowActionResponseStatus {
  VALIDATION_STATUS_UNKNOWN = 'VALIDATION_STATUS_UNKNOWN',
  VALIDATION_STATUS_VALIDATING = 'VALIDATION_STATUS_VALIDATING',
  VALIDATION_STATUS_VALID = 'VALIDATION_STATUS_VALID',
  VALIDATION_STATUS_INVALID = 'VALIDATION_STATUS_INVALID',
}
export enum ValidateWorkflowConfigRequestRunnerMode {
  RUNNER_MODE_UNSET = 'RUNNER_MODE_UNSET',
  RUNNER_MODE_CLOUD = 'RUNNER_MODE_CLOUD',
  RUNNER_MODE_HYBRID = 'RUNNER_MODE_HYBRID',
  RUNNER_MODE_INVALID = 'RUNNER_MODE_INVALID',
}
export enum WorkflowRunnerMode {
  RUNNER_MODE_UNSET = 'RUNNER_MODE_UNSET',
  RUNNER_MODE_CLOUD = 'RUNNER_MODE_CLOUD',
  RUNNER_MODE_HYBRID = 'RUNNER_MODE_HYBRID',
  RUNNER_MODE_INVALID = 'RUNNER_MODE_INVALID',
}
export enum WorkflowRunRunnerMode {
  RUNNER_MODE_UNSET = 'RUNNER_MODE_UNSET',
  RUNNER_MODE_CLOUD = 'RUNNER_MODE_CLOUD',
  RUNNER_MODE_HYBRID = 'RUNNER_MODE_HYBRID',
  RUNNER_MODE_INVALID = 'RUNNER_MODE_INVALID',
}
export enum WorkflowRunStatus {
  RUN_STATUS_UNKNOWN = 'RUN_STATUS_UNKNOWN',
  RUN_STATUS_CREATED = 'RUN_STATUS_CREATED',
  RUN_STATUS_PENDING = 'RUN_STATUS_PENDING',
  RUN_STATUS_ACTIVE = 'RUN_STATUS_ACTIVE',
  RUN_STATUS_ERROR = 'RUN_STATUS_ERROR',
  RUN_STATUS_LOST = 'RUN_STATUS_LOST',
  RUN_STATUS_COMPLETED = 'RUN_STATUS_COMPLETED',
  RUN_STATUS_CANCELLING = 'RUN_STATUS_CANCELLING',
  RUN_STATUS_CANCELLED = 'RUN_STATUS_CANCELLED',
}
export enum WorkflowTaskStatus {
  RUN_STATUS_UNKNOWN = 'RUN_STATUS_UNKNOWN',
  RUN_STATUS_CREATED = 'RUN_STATUS_CREATED',
  RUN_STATUS_PENDING = 'RUN_STATUS_PENDING',
  RUN_STATUS_ACTIVE = 'RUN_STATUS_ACTIVE',
  RUN_STATUS_ERROR = 'RUN_STATUS_ERROR',
  RUN_STATUS_LOST = 'RUN_STATUS_LOST',
  RUN_STATUS_COMPLETED = 'RUN_STATUS_COMPLETED',
  RUN_STATUS_CANCELLING = 'RUN_STATUS_CANCELLING',
  RUN_STATUS_CANCELLED = 'RUN_STATUS_CANCELLED',
}
