import * as React from 'react';
import { Components, Theme } from '@mui/material';
import { ChevronLeft, ChevronRight } from 'src/resources/icons/figma';

const MuiPaginationItem: Components<Theme>['MuiPaginationItem'] = {
  defaultProps: {
    components: {
      previous: () => <ChevronLeft width={16} height={16} />,
      next: () => <ChevronRight width={16} height={16} />,
    },
  },
  styleOverrides: {
    // Don't use 'root' or 'item' or you could style the '...'
    page: ({ theme }) => ({
      color: theme.palette.grey[900],
      ':hover,&.Mui-selected,&.Mui-selected:hover': {
        backgroundColor: theme.palette.primary[100],
      },
      ':active': {
        backgroundColor: theme.palette.primary[200],
      },
    }),
    // Styling the Chevrons
    previousNext: ({ theme }) => ({
      color: theme.palette.grey[900],
      ':hover,&.Mui-selected,.Mui-selected:hover': {
        backgroundColor: theme.palette.primary[100],
      },
      ':active': {
        backgroundColor: theme.palette.primary[200],
      },
      '&.Mui-disabled': {
        color: theme.palette.grey[400],
        opacity: 1,
      },
    }),
  },
};

export default MuiPaginationItem;
