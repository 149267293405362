import { ProjectsAPI } from './projects';

export const LambdaAPI = {
  reducerPath: ProjectsAPI.reducerPath,
  reducer: ProjectsAPI.reducer,
  middleware: ProjectsAPI.middleware,
};

export { AuthAPI } from './auth';
export { ProjectsAPI } from './projects';
