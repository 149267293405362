import { Components, Theme } from '@mui/material';

const MuiAvatarGroup: Components<Theme>['MuiAvatarGroup'] = {
  styleOverrides: {
    root: ({ ownerState: { max = 0 } }) => ({
      // This works, and if you don't want it let's reverse the overlapping of the avatars
      ...[...Array(max)].reduce(
        (result, curr, index) => ({
          ...result,
          [`& > .MuiAvatar-root:nth-of-type(${index + 1})`]: {
            zIndex: max - index,
          },
        }),
        {}
      ),
    }),
    avatar: {
      border: 'none',
    },
  },
};

export default MuiAvatarGroup;
