import * as React from 'react';
import { toast } from 'react-hot-toast';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { Download, Triangle } from 'icons/figma';
import { downloadFromLink } from 'utils/data';

type DownloadRecordButtonProps = Omit<ButtonProps, 'onClick' | 'type'> & {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => boolean;
  disabled?: boolean;
  lazyHook: () => [
    trigger: (options?: Record<string, unknown>) => void, //options is actually fetchArgs
    result: Record<string, unknown>,
    lastPromiseInfo: Record<string, unknown>,
  ];
  fetchArgs: Record<string, unknown>;
  snackbar?: string;
  tooltip?: string;
};

const LOADING_STYLE = {
  backgroundColor: 'grey.100',
  color: 'grey.400',
};

// eslint-disable-next-line func-style
function assertDataUrl(data: unknown): asserts data is {
  url: string;
} {
  try {
    if (data !== null && typeof data === 'object') {
      if ('url' in data && typeof data['url'] === 'string') {
        // Correct
      } else {
        throw Error();
      }
    } else {
      throw Error();
    }
  } catch (err) {
    // TODO log to DataDog
  }
}

const RecordButton = React.forwardRef<
  HTMLButtonElement,
  DownloadRecordButtonProps
>(
  (
    {
      children,
      lazyHook: useArtifactLazyHook,
      fetchArgs,
      onClick = () => true,
      startIcon = <Download height={16} width={16} />,
      snackbar,
      disabled = false,
      ...buttonProps
    },
    ref
  ) => {
    const [downloaded, setDownloaded] = React.useState(false);
    const [loadArtifact, artifactQuery] = useArtifactLazyHook();

    const handleClick = React.useCallback(
      event => {
        if (artifactQuery.isFetching || disabled || onClick(event) === false) {
          return false;
        }
        setDownloaded(false);
        loadArtifact(fetchArgs);
      },
      [onClick, artifactQuery.isFetching, disabled, loadArtifact, fetchArgs]
    );

    React.useEffect(() => {
      if (
        artifactQuery.isSuccess &&
        artifactQuery.data &&
        !downloaded &&
        !disabled
      ) {
        assertDataUrl(artifactQuery.data);
        downloadFromLink(artifactQuery.data.url);
        setDownloaded(true);
        if (snackbar) {
          toast.success(snackbar);
        }
      }
    }, [
      artifactQuery.isSuccess,
      artifactQuery.data,
      downloaded,
      disabled,
      snackbar,
    ]);
    return (
      <Button
        {...buttonProps}
        sx={{
          ...buttonProps.sx,
          ...(artifactQuery.isFetching ? LOADING_STYLE : undefined),
        }}
        onClick={handleClick}
        disabled={disabled}
        ref={ref}
        startIcon={
          artifactQuery.isFetching ? (
            <CircularProgress size={16} sx={{ color: LOADING_STYLE.color }} />
          ) : artifactQuery.isError ? (
            <Triangle height={16} width={16} />
          ) : (
            startIcon
          )
        }
      >
        {children}
      </Button>
    );
  }
);

RecordButton.displayName = 'RecordButton';

export default RecordButton;
