import * as React from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Link,
  styled,
  Toolbar,
  Typography,
} from '@mui/material';

const StyledTextContainer = styled(Container)(({ theme }) => ({
  '&&': { paddingLeft: theme.spacing(30), position: 'relative' },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: theme.spacing(14),
  boxShadow: theme.shadows[1],
  padding: theme.spacing(0, 6),
  justifyContent: 'flex-end',
}));

const StyledLogoutButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body1Strong,
  color: theme.palette.text.secondary,
  margin: theme.spacing(0, 1),
  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
  },
}));

const StyledAwwSadBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.secondary,
  width: '128px',
  height: '128px',
  borderRadius: '50%',
  position: 'absolute',
  left: 0,
  top: theme.spacing(-12),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '4.5rem',
  zIndex: -1,
}));

type ErrorLayoutProps = {
  handleRefresh: () => void;
  handleLogout: () => void;
};

const ErrorLayout = ({ handleRefresh, handleLogout }: ErrorLayoutProps) => {
  return (
    <Box
      id="error-page-element"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AppBar position="relative">
        <StyledToolbar>
          <StyledLogoutButton variant="text" onClick={handleLogout}>
            Logout
          </StyledLogoutButton>
        </StyledToolbar>
      </AppBar>
      <Container
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <StyledTextContainer maxWidth="sm">
          <Typography variant="h1" sx={{ mb: 3 }}>
            Something went wrong.
          </Typography>

          <Typography paragraph color="textSecondary" sx={{ my: 4 }}>
            It looks like an unexpected error has occurred.
          </Typography>

          <Grid container gap={2} sx={{ my: 4 }}>
            <Button
              variant="outlined"
              onClick={handleRefresh}
              sx={{ cursor: 'pointer' }}
            >
              Try again
            </Button>
            <Button
              variant="text"
              href="/"
              target="_self"
              sx={{ cursor: 'pointer' }}
            >
              Back to Home
            </Button>
          </Grid>

          <Typography paragraph color="textSecondary" sx={{ my: 4 }}>
            If the issue persists, please contact us at{' '}
            <Link href="mailto:support@gretel.ai">support@gretel.ai</Link>.
          </Typography>
          <StyledAwwSadBox>&#128542;</StyledAwwSadBox>
        </StyledTextContainer>
      </Container>
    </Box>
  );
};

export default ErrorLayout;
