import * as React from 'react';
import { AvatarGroup, AvatarProps, Tooltip } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { User } from 'src/api/types/User';
import Formatters from 'utils/formatters';
import { UserAvatarInner } from './UserAvatarInner';

type Size = 'small' | 'medium' | 'large' | 'xLarge';

const sizeToSpacing = {
  small: 6,
  medium: 8,
  large: 10,
  xLarge: 16,
};

type UserAvatarProps = AvatarProps & {
  user: Partial<User> | Partial<User>[];
  size?: Size;
  max?: number;
  total?: number;
};

const UserAvatar = ({
  user,
  max = 6,
  size = 'medium',
  total,
  ...avatarProps
}: UserAvatarProps) => {
  const users = Array.isArray(user) ? user : [user];
  return (
    <AvatarGroup
      max={max}
      sx={theme => ({
        justifyContent: 'flex-end',
        flexDirection: 'row-reverse',
        '& .MuiAvatar-root': {
          width: theme.spacing(sizeToSpacing[size]),
          height: theme.spacing(sizeToSpacing[size]),
          ...(size === 'small' && {
            ...theme.typography.h6,
            fontWeight: theme.typography.fontWeightMedium,
          }),
        },
      })}
      total={total}
    >
      {users.filter(Boolean).map((currentUser, index) => {
        const {
          _id = uuidv4(),
          email,
          username,
          firstname,
          lastname,
          image,
        } = currentUser;

        return (
          <Tooltip
            variant="dark"
            key={`${_id}.${index}`}
            arrow
            placement="top"
            title={Formatters.User.nameWithAccessLevel(currentUser)}
          >
            <UserAvatarInner
              _id={_id}
              email={email}
              username={username}
              firstname={firstname}
              lastname={lastname}
              image={image}
              {...avatarProps}
            />
          </Tooltip>
        );
      })}
    </AvatarGroup>
  );
};

export default UserAvatar;
