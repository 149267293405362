import { Components, Theme } from '@mui/material';

declare module '@mui/material/Tooltip' {
  interface TooltipProps {
    /** **QUBIT SPECIAL**
     * We added custom prop to handle the different Tooltip styling we
     * were using 1-offs for -walston */
    variant: 'dark' | 'light' | 'success' | 'error';
  }
}

/**
 * @todo -walston
 * we can move the 4 different types of tooltip stylings into this file
 * if we create a new prop. Nikko came up with the idea and put together
 * a little example, and I've created a follow up ticket to do the work.
 * https://gretel.atlassian.net/browse/INT-555 */
const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme, ownerState }) => {
      const defaultStyles = {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1, 2),
        fontSize: theme.spacing(3),
        boxShadow: theme.shadows[4],
      };
      switch (ownerState?.variant) {
        case 'dark':
          return {
            ...defaultStyles,
            backgroundColor: theme.palette.primary[950],
            color: theme.palette.common.white,
          };
        case 'light':
          return {
            ...defaultStyles,
            backgroundColor: theme.palette.background.secondary,
            color: theme.palette.text.primary,
          };
        case 'success':
          return {
            ...defaultStyles,
            backgroundColor: theme.palette.success.dark,
            color: theme.palette.common.white,
          };
        case 'error':
          return {
            ...defaultStyles,
            backgroundColor: theme.palette.error[800],
            color: theme.palette.common.white,
          };
        default:
          return { ...defaultStyles };
      }
    },
    arrow: ({ theme, ownerState }) => {
      switch (ownerState?.variant) {
        case 'dark':
          return { color: theme.palette.primary[950] };
        case 'light':
          return { color: theme.palette.background.secondary };
        case 'success':
          return { color: theme.palette.success.dark };
        case 'error':
          return { color: theme.palette.error[800] };
      }
    },
  },
};

export default MuiTooltip;
