import * as React from 'react';
import { Box, Button, FormHelperText } from '@mui/material';
import PermissionMenu from 'common/input/PermissionMenu';
import UserEmailInput from 'common/input/UserEmailInput';
import VerticalLabel from 'common/input/VerticalLabel';
import { USER_ACCESS_LEVEL } from 'constants/userAccessLevel';
import { AlertCircle } from 'icons/figma';
import useEmailValidation from 'utils/useEmailValidation';
import { SuccessLevel } from '../types';

type Props = {
  onSubmit: ({ email, level }: { email: string; level: number }) => void;
  maxLevel?: USER_ACCESS_LEVEL;
  successLevel: SuccessLevel;
};

export const InviteTabContent = ({
  onSubmit,
  maxLevel,
  successLevel,
}: Props) => {
  const [email, setEmail] = React.useState('');
  const [level, setLevel] = React.useState(1);
  const [menuWidth, setMenuWidth] = React.useState(117);
  const [validator, validatorStatus] = useEmailValidation();

  const menuRef = React.useRef<HTMLButtonElement>(null);

  const handleSubmit = React.useCallback(
    event => {
      event.preventDefault();
      if (email && validator(email) && level) {
        onSubmit({ email, level });
      }
    },
    [onSubmit, email, level, validator]
  );

  React.useLayoutEffect(() => {
    if (menuRef?.current) {
      setMenuWidth(menuRef?.current?.clientWidth);
    }
  }, []);

  return (
    <>
      <Box display="flex" gap={2}>
        <Box sx={{ position: 'relative', flexGrow: 1 }}>
          <VerticalLabel label="Email address">
            <UserEmailInput
              onChange={event => setEmail(event?.target?.value)}
              InputProps={{ style: { paddingRight: menuWidth } }}
              placeholder="name@example.com"
              error={validatorStatus.error}
            />
          </VerticalLabel>
          <PermissionMenu
            ref={menuRef}
            value={level}
            onChange={(event, newValue) => setLevel(newValue)}
            maxLevel={maxLevel}
            sx={theme => ({
              position: 'absolute',
              margin: 'auto',
              top: theme.spacing(7),
              right: theme.spacing(0.5),
            })}
          />
        </Box>
        <Button
          id={`model-complete-${successLevel}-invite`}
          onClick={handleSubmit}
          disabled={!email}
          color="primary"
          type="submit"
          sx={{ alignSelf: 'flex-end' }}
        >
          Invite
        </Button>
      </Box>
      <Box>
        <FormHelperText
          sx={{
            fontSize: '12px',
          }}
        >
          Your teammates will get an email that gives them access to your
          project.
        </FormHelperText>
        {validatorStatus.error && (
          <FormHelperText error>
            <AlertCircle height={16} width={16} />
            Please enter a valid email address
          </FormHelperText>
        )}
      </Box>
    </>
  );
};
