import * as React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { omit } from 'lodash';
import { Trash2 } from 'icons/figma';

type Props = Omit<DialogProps, 'content'> & {
  title: React.ReactNode;
  content: React.ReactNode;
  onDelete: React.MouseEventHandler<HTMLButtonElement>;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  hideIcon?: boolean;
  cancelLabel?: string;
  deleteLabel?: string;
};

const DeleteModal = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      content,
      cancelLabel = 'Nevermind',
      deleteLabel = 'Delete',
      onDelete,
      onCancel,
      isLoading,
      hideIcon = false,
      ...props
    },
    ref
  ) => (
    // React throws warning about anchorEl
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onCancel}
      {...omit(props, 'anchorEl')}
      ref={ref}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && <DialogContent>{content}</DialogContent>}
      <DialogActions>
        <Button
          color="error"
          onClick={onDelete}
          startIcon={hideIcon ? null : <Trash2 height={16} width={16} />}
          disabled={isLoading}
          data-testid="action-confirm"
        >
          {deleteLabel}
        </Button>
        <Button onClick={onCancel} disabled={isLoading} variant="text">
          {cancelLabel}
        </Button>
        {isLoading && <CircularProgress size={24} />}
      </DialogActions>
    </Dialog>
  )
);

DeleteModal.displayName = 'DeleteModal';

export default DeleteModal;
