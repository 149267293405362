import { UseCaseBlueprint } from 'src/api/types/Blueprint';

export const FROM_SCRATCH_CARD: UseCaseBlueprint = {
  gtmId: 'from-scratch',
  title: 'Start from scratch',
  cardType: 'Console',
  description:
    'Pick your own model type or select an existing model to get started.',
  icon: 'start-from-scratch.png',
};

/** TODO(INT-2070) a centralized reference for use-case-navigator which does
 * exist in gretellabs/gretel-blueprints BUT
 * we'll have to override some default behavior in the Console for */
export const NAVIGATOR_CARD: Partial<UseCaseBlueprint> = {
  /* TODO(INT-2070) change this back to `'use-case-navigator'` */
  gtmId: 'use-case-navigator',
  detailsFileName: 'navigator.md',
};
