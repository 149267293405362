import { PropsWithChildren } from 'react';
import { SingleFileUploaderProps } from 'common/Upload/FileManager/SingleFileUploader';

export enum PromptMode {
  EXISTING = 'existing',
  TEXT = 'text',
  SQL = 'sql',
}
export type PlaygroundPromptProps = PropsWithChildren & { readOnly?: boolean };

export type RangeTuple = readonly [number, number];
export type Field = {
  readonly label: string;
  readonly field: string;
  readonly value: number;
  readonly range: RangeTuple;
  readonly advanced: boolean;
  readonly tooltip: string;
};

export type ExampleTableImportMetadata = {
  headers: string[];
  rows: Record<string, string>[];
};

export type ExampleTableImport = {
  error: string;
  file: ExampleTableImportMetadata | null;
};

export type TableData = {
  columns: string[];
  rows: Record<string, string>[];
};

export type NavigatorUploadMetadata = Pick<
  SingleFileUploaderProps,
  'stage' | 'error' | 'size' | 'name'
>;
