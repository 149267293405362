import { userAccessLabelMap } from 'constants/userAccessLevel';
import { User as UserType } from 'src/api/types/User';

type UserNames = Pick<
  UserType,
  'firstname' | 'lastname' | 'username' | 'email'
>;
export const UserFormatter = {
  censorAPIKey: (apiKey?: string) =>
    apiKey ? apiKey.slice(0, 4).concat('•'.repeat(apiKey.length - 4)) : '',
  name: (user: Partial<UserNames & { user_email?: string }>) => {
    const {
      firstname = '',
      lastname = '',
      email = '',
      user_email = '',
    } = user || {}; // Defaulting here instead of params for falsey eval (null !== undefined)
    return `${
      firstname || lastname
        ? [firstname, lastname].join(' ')
        : user_email || email // Inconsistent API
    }`.trim();
  },
  nameWithAccessLevel: (user: Partial<UserNames & { level?: string }>) => {
    const {
      firstname = '',
      lastname = '',
      username = '',
      level = '',
    } = user || {}; // Defaulting here instead of params for falsey eval (null !== undefined)
    return `${
      firstname || lastname ? [firstname, lastname].join(' ') : username
    }${level ? ` (${userAccessLabelMap[level]})` : ''}`.trim();
  },
  oauth: (serviceKey: string) => {
    switch (serviceKey) {
      case 'google':
        return 'Google';

      case 'github':
        return 'GitHub';

      case 'magic-link':
        return 'WorkOS Magic Link';

      case 'sso':
        return 'SSO';

      default:
        return '';
    }
  },
};
