import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link as InternalLink } from 'react-router-dom';
import {
  alpha,
  Box,
  Card,
  CardActionArea,
  CardContent,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  SvgIcon,
  Typography,
} from '@mui/material';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: 0,
  ':disabled, .MuiListItemButton-root:disabled': {
    pointerEvents: 'none',
  },
  '.MuiListItemButton-root': {
    padding: theme.spacing(1, 3),
    borderRadius: theme.shape.radii.medium,
    ':hover': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  ':focus, .MuiListItemButton-root:focus': {
    backgroundColor: 'inherit',
    borderRadius: theme.shape.radii.medium,
    boxShadow: `0 0 0 2px ${theme.palette.primary[200]}`,
  },
  ':active, &.Mui-focusVisible, &.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.activatedOpacity
    ),
  },
}));

/**
 * Update List Item behind feature flag, remove existing with removing FF
 */
const StyledUpdatedListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  ':disabled, .MuiListItemButton-root:disabled': {
    pointerEvents: 'none',
  },
  '.MuiListItemButton-root': {
    padding: theme.spacing(1, 4),
    borderRadius: theme.shape.radii.medium,
    '&:hover': {
      backgroundColor: alpha(
        theme.palette.secondary.main,
        theme.palette.action.hoverOpacity
      ),
    },
    '&.Mui-selected,&:hover.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.secondary.main,
        theme.palette.action.selectedOpacity
      ),
    },
  },
  ':focus, .MuiListItemButton-root:focus': {
    backgroundColor: 'inherit',
    borderRadius: theme.shape.radii.medium,
    boxShadow: `0 0 0 2px ${theme.palette.primary[200]}`,
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  marginLeft: theme.spacing(0),
  '& path': {
    vectorEffect: 'non-scaling-stroke', // To fix a stroke scaling issue
  },
}));

const UpdatedStyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '& path': {
    vectorEffect: 'non-scaling-stroke', // To fix a stroke scaling issue
  },
}));

export const PrimaryLinkItem = ({
  name,
  to,
  icon,
  disabled,
  id,
}: {
  name: string;
  id: string;
  icon: React.ReactNode;
  disabled: boolean;
  to: RouteComponentProps['location'];
}) => {
  return (
    <StyledListItem>
      <ListItemButton
        id={`Sidebar_${id}`}
        component={InternalLink}
        to={to}
        disabled={disabled}
      >
        <StyledListItemIcon>{icon}</StyledListItemIcon>
        <ListItemText
          primary={name}
          primaryTypographyProps={{ variant: 'body1Medium' }}
        />
      </ListItemButton>
    </StyledListItem>
  );
};

/**
 * Updated Primary Link Item.
 */
export const UpdatedPrimaryLinkItem = ({
  name,
  to,
  icon,
  disabled,
  id,
  selected = false,
}: {
  name: React.ReactNode;
  id: string;
  icon: React.ReactNode;
  disabled: boolean;
  to: RouteComponentProps['location'];
  selected?: boolean;
}) => {
  return (
    <StyledUpdatedListItem>
      <ListItemButton
        id={`Sidebar_${id}`}
        component={InternalLink}
        to={to}
        disabled={disabled}
        selected={selected}
      >
        <UpdatedStyledListItemIcon>{icon}</UpdatedStyledListItemIcon>
        <ListItemText
          primary={name}
          primaryTypographyProps={{ variant: 'body1Medium' }}
        />
      </ListItemButton>
    </StyledUpdatedListItem>
  );
};

export const SecondaryLinkItem = ({
  name,
  to,
  disabled,
  id,
}: React.PropsWithChildren<{
  name: string | React.ReactNode;
  id: string;
  disabled: boolean;
  to: RouteComponentProps['location'];
}>) => {
  return (
    <StyledListItem>
      <ListItemButton
        id={`Sidebar_${id}`}
        disabled={disabled}
        sx={{ pl: 3 }}
        component={InternalLink}
        to={to}
      >
        <ListItemText
          primary={name}
          primaryTypographyProps={{ variant: 'body2Medium' }}
        />
      </ListItemButton>
    </StyledListItem>
  );
};

export const FeaturedCardLink = ({
  name,
  to,
  icon,
  disabled,
  id,
}: {
  name: string;
  id: string;
  icon: React.ReactNode;
  disabled: boolean;
  to: RouteComponentProps['location'];
}) => {
  return (
    <Card
      sx={theme => ({
        flex: '0 0 auto',
        border: 'none',
        borderRadius: theme.shape.radii.large,
      })}
    >
      <CardActionArea
        component={InternalLink}
        to={to}
        id={`Sidebar_${id}`}
        disabled={disabled}
        sx={{
          bgcolor: 'primary.contrastText',
          color: 'primary.main',
          '&.MuiSelected,&:active': {
            color: 'common.white',
            bgcolor: 'primary.500',
            '&:hover': {
              bgcolor: 'primary.main',
            },
          },
        }}
      >
        <CardContent
          sx={{
            py: 4,
            pl: 3,
            pr: 2,
          }}
        >
          <Typography variant="body2Strong" component="div">
            Beta
          </Typography>
          <Box>
            <SvgIcon
              sx={{
                height: 16,
                width: 16,
                mt: 2,
                mr: 3,
                '& path': {
                  vectorEffect: 'non-scaling-stroke', // To fix a stroke scaling issue
                },
              }}
            >
              {icon}
            </SvgIcon>
            <Typography variant="body1Medium">{name}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
