import { matchPath } from 'react-router';
import { datadogRum } from '@datadog/browser-rum';
import {
  createListenerMiddleware,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';
import { UseCaseBlueprint } from 'src/api/types/Blueprint';
import { ProjectType } from 'src/api/types/Project';
import { DASHBOARD_ROUTE, WORKFLOWS_ROUTE } from 'src/routes';

export enum DataSourceType {
  uploadYourOwn = 'uploadYourOwn',
  sampleData = 'sampleData',
  chooseExisting = 'chooseExisting',
  connection = 'connection',
  sampleConnection = 'sampleConnection',
  gretel = 'gretel',
}

export type UseCasesState = {
  selectedUseCase?: UseCaseBlueprint;
  selectedDataSource?: { type: DataSourceType; fileName?: string };
  selectedProject?: {
    guid: string;
    display_name: string;
    runner_mode?: ProjectType;
  };
  isBuildingNewWorkflow: boolean;
  /**
   * This the path of the route that the user navigated from to
   * get to the use cases page. It is used to redirect the user back
   * if they cancel the use case flow.
   */
  referer: string;
};

export const initialState: UseCasesState = {
  selectedUseCase: undefined,
  selectedDataSource: undefined,
  selectedProject: undefined,
  isBuildingNewWorkflow: false,
  referer: DASHBOARD_ROUTE.path,
};

export const useCasesWorkflowDuck = createSlice({
  name: 'useCasesWorkflow',
  initialState,
  reducers: {
    chooseUseCase: (state, action) => {
      state.selectedUseCase = action.payload;
    },
    /**
     * Select Project:
     * Step 1 in our use case workflow process
     */
    chooseProject: (
      state,
      action: PayloadAction<{ guid: string; display_name: string }>
    ) => {
      state.selectedProject = action.payload;
    },
    /**
     * Select Model Type:
     * Step 2 in our start from scratch workflow process
     */
    chooseModelType: (state, action) => {
      if (state.selectedUseCase) {
        state.selectedUseCase.modelCategory = action.payload.modelCategory;
        state.selectedUseCase.modelType = action.payload.modelType;
        state.selectedUseCase.sampleDataset = action.payload.sampleDataset;
        state.selectedUseCase.defaultConfig = action.payload.defaultConfig;
      }
    },
    /**
     * Select Data Source:
     * Step 2 in our use case workflow process
     * or Step 3 if we're in start from scratch workflow
     */
    chooseDataSource: (
      state,
      action: PayloadAction<UseCasesState['selectedDataSource']>
    ) => {
      state.selectedDataSource = action.payload;
    },
    /**
     * Used to put us in the "creating a new workflow" flow, which limits data source options,
     * changes the steps, and has us finishing the Configure  page for a workflow rather than just a model.
     */
    setIsBuildingNewWorkflow: state => {
      state.isBuildingNewWorkflow = true;
      // Set a default referer to the workflows page in case a referer is never set
      state.referer = WORKFLOWS_ROUTE.path;
    },

    /**
     * reset full state. should be called on any cancel step within the workflow
     */
    reset: () => initialState,
  },
  extraReducers: base => {
    base.addCase(
      LOCATION_CHANGE,
      (
        state,
        action: PayloadAction<
          {
            location: {
              pathname: string;
              state?: { from?: { pathname: string } };
            };
          },
          typeof LOCATION_CHANGE
        >
      ) => {
        const isUseCasesRoute = matchPath(action.payload?.location?.pathname, {
          path: '/use_cases/cards/:card_id',
        });

        // If leaving the use cases route
        // we want to reset the use cases state.
        // note: this resets state on every navigation that doesn't have this pathname,
        // even on initial load :(
        if (!isUseCasesRoute) {
          return initialState;
        } else {
          /**
           * Sets the referer state to the previous route if the previous route
           * isn't a use case route. This is used to redirect the user back to
           * where the came from on cancel. This is only set if it isn't already set.
           */

          // There should be a recursive history nested under `state.from` but might be
          // unset if page is navigated to directly or using `history.[method]` instead
          // of the `go` method returned from `useConsoleRoute.
          const potentialReferer =
            action.payload.location?.state?.from?.pathname;

          const isFromUseCasesRoute = matchPath(potentialReferer ?? '', {
            path: '/use_cases/cards/:card_id',
          });

          // Only want to set the referer if is available and not from a use case route
          // e.g. entering the use flow for the first time.
          if (potentialReferer && !isFromUseCasesRoute) {
            state.referer = potentialReferer;
          }
        }
      }
    );
  },
});

export const useCasesWorkflowMiddleware = createListenerMiddleware({});
useCasesWorkflowMiddleware.startListening({
  matcher: isAnyOf(...Object.values(useCasesWorkflowDuck.actions)),
  effect: action => {
    datadogRum.addAction('useCaseCardWorkflow', action);
  },
});
