import { components } from './workflowConfig';
import { WorkflowsAPI } from './workflows';

// https://github.com/reduxjs/redux-toolkit/discussions/2506
// Only one reducer/middleware needs to be set up since each
// endpoint will inject routes at the same reducer path. -md
export const PilotAPI = {
  reducerPath: WorkflowsAPI.reducerPath,
  reducer: WorkflowsAPI.reducer,
  middleware: WorkflowsAPI.middleware,
};

type FilterKeysMatching<
  KS,
  FK extends string,
> = KS extends `${string}${FK}${string}` ? KS : never;

type schemas = components['schemas'];

export type SourceConfigs = Pick<
  schemas,
  FilterKeysMatching<keyof schemas, 'Source'>
>;
export type DestinationConfigs = Pick<
  schemas,
  FilterKeysMatching<keyof schemas, 'Destination'>
>;
export type GretelTabular = Pick<
  schemas,
  FilterKeysMatching<keyof schemas, 'GretelTabular'>
>;

// Break out some valuable types for intellisense purposes. -md
export type WorkflowConfig = schemas['WorkflowConfig'];
export type WorkflowLimits = schemas['WorkflowLimits'];
export type TypedActionConfig = schemas['TypedActionConfig'];
export type Trigger = schemas['Trigger'];
export type CronTrigger = schemas['CronTrigger'];
export type SystemConfig = schemas['SystemConfig'];
export type GretelModelConfig = schemas['GretelModelConfig'];
export type RunParams = schemas['RunParams'];

export type ConnectionTypes = Omit<
  schemas,
  | 'WorkflowConfig'
  | 'WorkflowLimits'
  | 'TypedActionConfig'
  | 'Trigger'
  | 'CronTrigger'
  | 'SystemConfig'
  | 'GretelModelConfig'
  | 'RunParams'
  | keyof SourceConfigs
  | keyof DestinationConfigs
  | keyof GretelTabular
>;

export { ConnectionsAPI } from './connections';
export * as utils from './utils';
export { WorkflowsAPI } from './workflows';
