import * as React from 'react';
import { Box, IconButton, styled, Tooltip } from '@mui/material';
import { ChevronLeft, ChevronRight } from 'icons/figma';
import { useSidebarStatus } from '../SidebarStatusContext';

// Show Toggle button on Hover outside of sidenav.
const StyledHoverForButtonBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: -16,
  width: 16,
  ':hover #sidenav-toggle-button': {
    visibility: 'visible',
  },
}));

export const ToggleButton = React.memo(() => {
  const { isSidebarOpen, closeSidebar, openSidebar } = useSidebarStatus();
  return (
    <>
      <StyledHoverForButtonBox />
      <Tooltip
        variant="dark"
        title={isSidebarOpen ? 'Collapse' : 'Expand'}
        placement="right"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton
          id="sidenav-toggle-button"
          aria-label={isSidebarOpen ? 'Collapse' : 'Expand'}
          aria-controls="sidebar"
          color="secondary"
          onClick={isSidebarOpen ? closeSidebar : openSidebar}
          size="small"
          sx={theme => ({
            position: 'absolute',
            top: 80,
            right: -16,
            zIndex: theme.zIndex.drawer + 1,
            transition: `transform ${theme.transitions.duration.shortest}ms ease`,
            visibility: isSidebarOpen ? 'hidden' : 'visible',
          })}
        >
          {isSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </Tooltip>
    </>
  );
});

ToggleButton.displayName = 'ToggleButton';
