import { api } from './baseApi';

export const addTagTypes = ['Connections'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      listConnections: build.query<
        ListConnectionsApiResponse,
        ListConnectionsApiArg
      >({
        query: () => ({ url: `/v1/connections` }),
        providesTags: ['Connections'],
      }),
      createConnection: build.mutation<
        CreateConnectionApiResponse,
        CreateConnectionApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections`,
          method: 'POST',
          body: queryArg.createConnectionRequest,
        }),
        invalidatesTags: ['Connections'],
      }),
      getGretelConnectionWithCredentials: build.query<
        GetGretelConnectionWithCredentialsApiResponse,
        GetGretelConnectionWithCredentialsApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections/gretel`,
          params: { workflow_run_id: queryArg.workflowRunId },
        }),
        providesTags: ['Connections'],
      }),
      listSampleConnections: build.query<
        ListSampleConnectionsApiResponse,
        ListSampleConnectionsApiArg
      >({
        query: () => ({ url: `/v1/connections/sample_connections` }),
        providesTags: ['Connections'],
      }),
      searchConnections: build.query<
        SearchConnectionsApiResponse,
        SearchConnectionsApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections/search`,
          params: {
            query: queryArg.query,
            sort: queryArg.sort,
            limit: queryArg.limit,
            skip: queryArg.skip,
          },
        }),
        providesTags: ['Connections'],
      }),
      getConnection: build.query<GetConnectionApiResponse, GetConnectionApiArg>(
        {
          query: queryArg => ({
            url: `/v1/connections/${queryArg.connectionId}`,
          }),
          providesTags: ['Connections'],
        }
      ),
      deleteConnection: build.mutation<
        DeleteConnectionApiResponse,
        DeleteConnectionApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections/${queryArg.connectionId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Connections'],
      }),
      updateConnection: build.mutation<
        UpdateConnectionApiResponse,
        UpdateConnectionApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections/${queryArg.connectionId}`,
          method: 'PATCH',
          body: queryArg.updateConnectionRequest,
        }),
        invalidatesTags: ['Connections'],
      }),
      getConnectionWithCredentials: build.query<
        GetConnectionWithCredentialsApiResponse,
        GetConnectionWithCredentialsApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections/${queryArg.connectionId}/credentials`,
        }),
        providesTags: ['Connections'],
      }),
      validateConnectionCredentials: build.mutation<
        ValidateConnectionCredentialsApiResponse,
        ValidateConnectionCredentialsApiArg
      >({
        query: queryArg => ({
          url: `/v1/connections/${queryArg.connectionId}/validate-credentials`,
          method: 'POST',
        }),
        invalidatesTags: ['Connections'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as ConnectionsAPI };
export type ListConnectionsApiResponse =
  /** status 200 OK */ ListConnectionsResponse;
export type ListConnectionsApiArg = void;
export type CreateConnectionApiResponse = /** status 200 OK */ Connection;
export type CreateConnectionApiArg = {
  createConnectionRequest: CreateConnectionRequest;
};
export type GetGretelConnectionWithCredentialsApiResponse =
  /** status 200 OK */ Connection;
export type GetGretelConnectionWithCredentialsApiArg = {
  workflowRunId?: string;
};
export type ListSampleConnectionsApiResponse =
  /** status 200 OK */ ListConnectionsResponse;
export type ListSampleConnectionsApiArg = void;
export type SearchConnectionsApiResponse =
  /** status 200 OK */ SearchConnectionsResponse;
export type SearchConnectionsApiArg = {
  /** Query string to filter connections. Supported fields are: `project_id` */
  query?: string;
  /** Sort order for connections. Supported sort fields are: `created_at`, `updated_at` */
  sort?: string;
  /** Maximum number of connections to return. Default is no limit. */
  limit?: number;
  /** Number of connections to skip before applying limit. */
  skip?: number;
};
export type GetConnectionApiResponse = /** status 200 OK */ Connection;
export type GetConnectionApiArg = {
  connectionId: string;
};
export type DeleteConnectionApiResponse = /** status 200 OK */ string;
export type DeleteConnectionApiArg = {
  /** The connection ID to delete. */
  connectionId: string;
};
export type UpdateConnectionApiResponse = /** status 200 OK */ Connection;
export type UpdateConnectionApiArg = {
  /** The connection ID to update. */
  connectionId: string;
  updateConnectionRequest: UpdateConnectionRequest;
};
export type GetConnectionWithCredentialsApiResponse =
  /** status 200 OK */ Connection;
export type GetConnectionWithCredentialsApiArg = {
  connectionId: string;
};
export type ValidateConnectionCredentialsApiResponse =
  /** status 200 OK */ ValidateConnectionCredentialsResponse;
export type ValidateConnectionCredentialsApiArg = {
  connectionId: string;
};
export type Connection = {
  id: string;
  type: string;
  name: string;
  validation_status:
    | 'VALIDATION_STATUS_UNKNOWN'
    | 'VALIDATION_STATUS_VALIDATING'
    | 'VALIDATION_STATUS_VALID'
    | 'VALIDATION_STATUS_INVALID';
  credentials?: object;
  config?: object;
  encrypted_credentials?: object;
  customer_managed_credentials_encryption: boolean;
  created_at: string;
  project_id: string;
  created_by: string;
  connection_target_type?: string;
  auth_strategy?: string;
};
export type ListConnectionsResponse = {
  data?: Connection[];
};
export type GoogleProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type Status = {
  code?: number;
  message?: string;
  details?: GoogleProtobufAny[];
};
export type CreateConnectionRequest = {
  project_id: string;
  name?: string;
  type: string;
  credentials?: object;
  encrypted_credentials?: object;
  config?: object;
  connection_target_type?: string;
  auth_strategy?: string;
};
export type SearchConnectionsResponse = {
  connections?: Connection[];
  total: number;
};
export type UpdateConnectionRequest = {
  name?: string;
  credentials?: object;
  encrypted_credentials?: object;
  config?: object;
  connection_target_type?: string;
  auth_strategy?: string;
};
export type ValidateConnectionCredentialsResponse = {
  status?:
    | 'VALIDATION_STATUS_UNKNOWN'
    | 'VALIDATION_STATUS_VALIDATING'
    | 'VALIDATION_STATUS_VALID'
    | 'VALIDATION_STATUS_INVALID';
  message?: string;
};
export const {
  useListConnectionsQuery,
  useCreateConnectionMutation,
  useGetGretelConnectionWithCredentialsQuery,
  useListSampleConnectionsQuery,
  useSearchConnectionsQuery,
  useGetConnectionQuery,
  useDeleteConnectionMutation,
  useUpdateConnectionMutation,
  useGetConnectionWithCredentialsQuery,
  useValidateConnectionCredentialsMutation,
} = injectedRtkApi;
