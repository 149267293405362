import * as React from 'react';
import { Components, Theme } from '@mui/material';
import { XCircle as CircleX } from 'icons/figma';

declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    large: true;
  }
  interface ChipClasses {
    labelLarge: true;
    deleteIconLarge: true;
    colorDefault: true;
    deleteIconColorDefault: true;
    deleteIconColorSuccess: true;
    deleteIconColorWarning: true;
    deleteIconColorError: true;
    deleteIconColorInfo: true;
  }
}

const MuiChip: Components<Theme>['MuiChip'] = {
  defaultProps: {
    deleteIcon: <CircleX />,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.body2Strong,
      borderRadius: 9999,
      height: 'auto',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      '&.Mui-focusVisible,:focus': {
        boxShadow: `0px 0px 0px 1px ${theme.palette.common.white}, 0px 0px 0px 3px ${theme.palette.primary[300]}`,
      },
      '&.MuiChip-sizeSmall': {
        lineHeight: theme.spacing(5),
        '&.MuiChip-deletable': {
          paddingRight: theme.spacing(1),
        },
      },
      '&.MuiChip-sizeMedium': {
        lineHeight: theme.spacing(6),
        '&.MuiChip-deletable': {
          paddingRight: theme.spacing(1),
        },
      },
      '&.MuiChip-sizeLarge': {
        lineHeight: theme.spacing(8),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        '&.MuiChip-deletable': {
          paddingRight: theme.spacing(2),
        },
      },
    }),
    label: {
      padding: 0,
    },
    labelSmall: ({ theme }) => ({
      height: theme.spacing(5),
    }),
    labelMedium: ({ theme }) => ({
      height: theme.spacing(6),
    }),
    labelLarge: ({ theme }) => ({
      height: theme.spacing(8),
    }),
    deleteIcon: ({ theme }) => ({
      borderRadius: '50%',
      width: theme.spacing(4),
      height: theme.spacing(4),
    }),
    deleteIconSmall: ({ theme }) => ({
      margin: 0,
      marginLeft: theme.spacing(1),
    }),
    deleteIconMedium: ({ theme }) => ({
      margin: 0,
      marginLeft: theme.spacing(2),
    }),
    deleteIconLarge: ({ theme }) => ({
      margin: 0,
      marginLeft: theme.spacing(2),
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
    }),
    colorDefault: ({ theme }) => ({
      color: theme.palette.grey[900],
      '&,&.Mui-focusVisible': { backgroundColor: theme.palette.grey[100] },
      '&.MuiChip-clickableColorDefault': {
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
        },
        '&:active': {
          backgroundColor: theme.palette.grey[300],
        },
      },
    }),
    deleteIconColorDefault: ({ theme }) => ({
      color: theme.palette.grey[700],
      fill: theme.palette.grey[200],
      ':hover,&.Mui-focusVisible': {
        color: theme.palette.grey[700],
        fill: theme.palette.grey[300],
      },
      ':active': {
        color: theme.palette.grey[700],
        fill: theme.palette.grey[400],
      },
    }),
    colorPrimary: ({ theme }) => ({
      color: theme.palette.primary[900],
      '&,&.Mui-focusVisible': { backgroundColor: theme.palette.primary[100] },
      '&.MuiChip-clickableColorPrimary': {
        '&:hover': {
          backgroundColor: theme.palette.primary[200],
        },
        '&:active': {
          backgroundColor: theme.palette.primary[300],
        },
      },
    }),
    deleteIconColorPrimary: ({ theme }) => ({
      color: theme.palette.primary[700],
      fill: theme.palette.primary[200],
      ':hover': {
        color: theme.palette.primary[700],
        fill: theme.palette.primary[300],
      },
      ':active': {
        color: theme.palette.primary[700],
        fill: theme.palette.primary.light,
      },
    }),
    colorSuccess: ({ theme }) => ({
      color: theme.palette.success[900],
      '&,&.Mui-focusVisible': { backgroundColor: theme.palette.success[100] },
      '&.MuiChip-clickableColorSuccess': {
        '&:hover': {
          backgroundColor: theme.palette.success.light,
        },
        '&:active': {
          backgroundColor: theme.palette.success[300],
        },
      },
    }),
    deleteIconColorSuccess: ({ theme }) => ({
      color: theme.palette.success.dark,
      fill: theme.palette.success.light,
      ':hover': {
        color: theme.palette.success.dark,
        fill: theme.palette.success[300],
      },
      ':active': {
        color: theme.palette.success.dark,
        fill: theme.palette.success[400],
      },
    }),
    colorWarning: ({ theme }) => ({
      color: theme.palette.warning[800],
      '&,&.Mui-focusVisible': { backgroundColor: theme.palette.warning[100] },
      '&.MuiChip-clickableColorWarning': {
        '&:hover': {
          backgroundColor: theme.palette.warning[200],
        },
        '&:active': {
          backgroundColor: theme.palette.warning.light,
        },
      },
    }),
    deleteIconColorWarning: ({ theme }) => ({
      color: theme.palette.warning.dark,
      fill: theme.palette.warning[200],
      ':hover': {
        color: theme.palette.warning.dark,
        fill: theme.palette.warning.light,
      },
      ':active': {
        color: theme.palette.warning.dark,
        fill: theme.palette.warning[400],
      },
    }),
    colorError: ({ theme }) => ({
      color: theme.palette.error[900],
      '&,&.Mui-focusVisible': { backgroundColor: theme.palette.error[100] },
      '&.MuiChip-clickableColorError': {
        '&:hover': {
          backgroundColor: theme.palette.error.light,
        },
        '&:active': {
          backgroundColor: theme.palette.error[300],
        },
      },
    }),
    deleteIconColorError: ({ theme }) => ({
      color: theme.palette.error.dark,
      fill: theme.palette.error.light,
      ':hover': {
        color: theme.palette.error.dark,
        fill: theme.palette.error[300],
      },
      ':active': {
        color: theme.palette.error.dark,
        fill: theme.palette.error[400],
      },
    }),
    colorInfo: ({ theme }) => ({
      color: theme.palette.info[900],
      '&,&.Mui-focusVisible': { backgroundColor: theme.palette.info[100] },
      '&.MuiChip-clickableColorInfo': {
        ':hover,&.Mui-focusVisible': {
          backgroundColor: theme.palette.info.light,
        },
        '&:active': {
          backgroundColor: theme.palette.info[300],
        },
      },
    }),
    deleteIconColorInfo: ({ theme }) => ({
      color: theme.palette.info.dark,
      fill: theme.palette.info.light,
      ':hover': {
        color: theme.palette.info.dark,
        fill: theme.palette.info[300],
      },
      ':active': {
        color: theme.palette.info.dark,
        fill: theme.palette.info[400],
      },
    }),
  },
};

export default MuiChip;
